import axios from 'axios';
import authHeader from './auth-header';

class IntegratedOfferService {
    getIntegratedOffer($integratedOfferId) {
        return axios.get(process.env.VUE_APP_API_URL + 'integrated-offer/view/'+$integratedOfferId, { 
            headers: authHeader(),
        })
        // .then(console.log).catch(console.error)
        ;
    }
    
    getIntegratedOffers() {
        return axios.get(process.env.VUE_APP_API_URL + 'integrated-offers/list', { 
            headers: authHeader(),
        })
        // .then(console.log).catch(console.error)
        ;
    }

    integratedOfferCreate(formData) {
        return axios.post(
            process.env.VUE_APP_API_URL + 'integrated-offer/create', //url
            formData, //params
            { //options
                withCredentials: false,
                headers: authHeader(),
            }
        )
        // .then(console.log).catch(console.error)
        ;
    }

    IntegratedOfferAssignIngredient(formData) {
        return axios.post(
            process.env.VUE_APP_API_URL + 'ingredient/assign-to-integrated-offer', //url
            formData, //params
            { //options
                withCredentials: false,
                headers: authHeader(),
            }
        )
        // .then(console.log).catch(console.error)
        ;
    }

    IntegratedOfferAssignProduct(formData) {
        return axios.post(
            process.env.VUE_APP_API_URL + 'product/assign-to-integrated-offer', //url
            formData, //params
            { //options
                withCredentials: false,
                headers: authHeader(),
            }
        )
        // .then(console.log).catch(console.error)
        ;
    }
}

export default new IntegratedOfferService();
