<template>
  <div class="col-md-12">
    <div v-if="integratedOffer">
      <p >integratedOffer: {{ integratedOffer.data.name }}</p>
    </div>
    <div class="card card-container">
    <form @submit.prevent="AssignMonetaryValueToIntegratedOffer">
      <div class="mb-3">
        <label for="monetary_value" class="form-label">Update Cost</label>
        <input type="text" class="form-control" id="monetary_value" v-model="monetary_value" required="required" ref="monetary_value">
      </div>
      <button type="submit" class="btn btn-primary">Update Cost</button>
    </form>

    <div v-if="message" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
        {{ message }}
      </div>
    </div>

  </div>
</template>

<script>

import MonetaryValueService from "../services/monetary-value.service";
import IntegratedOfferService from "../services/integrated-offer.service";
import { useRoute } from 'vue-router';

export default {
  monetary_value: "assing monetary value to an integrated offer",
  methods: {
    AssignMonetaryValueToIntegratedOffer() {
      this.formData = {
        'integrated_offer_id': this.integrated_offer_id,
        'monetary_value': this.monetary_value,
      };
      console.log('formData');
      console.log(this.formData);
      MonetaryValueService.AssignMonetaryValueToIntegratedOffer(this.formData).then(
          (response) => {
            this.message = response.data.message;
            this.successful = true;
            this.loading = false;

            this.formData = {};
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.successful = false;
            this.loading = false;
          }
        );
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  data() {
    return {
      //post status
      successful: false,
      loading: false,
      message: "",
      integratedOffer: '',

      //formdata
      formData: {},
      integrated_offer_id: '',
    };
  },
  mounted() {
    if (this.loggedIn) {
      // this.$router.push("/profile");
    }
    const route = useRoute()
    this.integrated_offer_id = route.params.integratedOfferId;

    IntegratedOfferService.getIntegratedOffer(route.params.integratedOfferId).then(
      (response) => {
        this.integratedOffer = response.data;
        console.log(this.integratedOffer);
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );

  },
};
</script>

<style scoped></style>