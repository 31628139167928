import axios from 'axios';
import authHeader from './auth-header';

class MonetaryValueService {
    AssignMonetaryValueToIngredient(formData) {
        return axios.post(
            process.env.VUE_APP_API_URL + 'monetary-value/assign-to-ingredient', //url
            formData, //params
            { //options
                withCredentials: false,
                headers: authHeader(),
            }
        )
        // .then(console.log).catch(console.error)
        ;
    }

    AssignMonetaryValueToProduct(formData) {
        return axios.post(
            process.env.VUE_APP_API_URL + 'monetary-value/assign-to-product', //url
            formData, //params
            { //options
                withCredentials: false,
                headers: authHeader(),
            }
        )
        // .then(console.log).catch(console.error)
        ;
    }

    AssignMonetaryValueToIntegratedOffer(formData) {
        return axios.post(
            process.env.VUE_APP_API_URL + 'monetary-value/assign-to-integrated-offer', //url
            formData, //params
            { //options
                withCredentials: false,
                headers: authHeader(),
            }
        )
        // .then(console.log).catch(console.error)
        ;
    }
}

export default new MonetaryValueService();
